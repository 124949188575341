




































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import _ from 'lodash'
import store from '@/store'
import { timeFromSeconds } from '@/util'

@Component({
  components: {
    RecycleScroller
  }
})
export default class Tags extends Vue {

  tags: any = []
  transcript = store.transcript!
  toTime = timeFromSeconds

  scrollToAudioTime(t: number) {
    const e = this.transcript.findEventAt(t)
    if (e !== undefined) {
      this.transcript.scrollToAudioEvent(e)
    }
  }

}
