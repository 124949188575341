

































































































import { Vue, Component, Prop } from 'vue-property-decorator'

import { history } from '../store/history.store'
import settings, { SidebarItem } from '../store/settings.store'

import { keyboardShortcuts, displayKeyboardAction } from '../service/keyboard.service'

import editHistory from './EditHistory.vue'
import WarningList from './WarningList.vue'
import Tags from './Tags.vue'
import Search from './Search.vue'
import Actions from './Actions.vue'
import { timeFromSeconds } from '@/util'
import Transcript from '@/classes/transcript.class'
import store from '@/store'

@Component({
  components: {
    Actions,
    editHistory,
    WarningList,
    Search,
    Tags
  }
})
export default class Sidebar extends Vue {

  @Prop({ default: false }) active!: boolean
  @Prop({ required: true }) transcript!: Transcript

  store = store
  settings = settings
  history = history
  stuckAtBottom = false
  toTime = timeFromSeconds
  keyboardShortcuts = keyboardShortcuts
  displayKeyboardAction = displayKeyboardAction

  clickTab(e: SidebarItem) {
    if (e === this.settings.activeSidebarItem && this.settings.showDrawer === true) {
      this.settings.showDrawer = false
    } else {
      this.settings.activeSidebarItem = e
      if (this.settings.showDrawer === false) {
        this.settings.showDrawer = true
      }
    }
  }

  beforeUpdate() {
    if (this.$el && this.$el.querySelector) {
      const el = this.$el.querySelector('.sidebar-scrollable')
      if (el !== null && el.scrollHeight - el.scrollTop - el.clientHeight < 25) {
        this.stuckAtBottom = true
      } else {
        this.stuckAtBottom = false
      }
    }
  }

  updated() {
    if (this.$el && this.$el.querySelector) {
      const el = this.$el.querySelector('.sidebar-scrollable')
      if (this.stuckAtBottom && el) {
        el.scrollTo({
          top: el.scrollHeight - el.clientHeight,
          behavior: 'smooth'
        })
      }
    }
  }

}
