

















































































import { Vue, Component } from 'vue-property-decorator'
import { TranscriptTier } from '@/types/transcript'
import store from '@/store'
import settings from '@/store/settings.store'

@Component
export default class SpeakerPanel extends Vue {

  settings = settings
  transcript = store.transcript!
  tierHeight = 25
  isBasicInfoValid = false

  get speakerHeight(): string {
    return this.transcript.meta.tiers.filter(t => t.show === true).length * this.tierHeight + 1 + 'px'
  }

  get secondaryVisibleTiers(): TranscriptTier[] {
    var r:any = {}
    Object.keys(this.transcript.meta.speakers).forEach(sId => {
      r[sId] = this.transcript.meta.tiers.filter(t => t.id !== this.transcript.meta.defaultTier && t.show[sId] === true)
    })
    return r
  }

  get areAllExpanded(): boolean {
    return this.transcript.meta.tiers.every(t => t.id === this.transcript.meta.defaultTier || (t.show === Object.keys(this.transcript.meta.speakers).forEach(sId => t.show[sId] === true)))
  }

  get areAllExpandedSpeaker(): boolean {
    var r:any = {}
    Object.keys(this.transcript.meta.speakers).forEach(sId => {
      r[sId] = this.transcript.meta.tiers.every(t => t.id === this.transcript.meta.defaultTier || t.show[sId] === true)
    })
    return r
  }

  get areAllCollapsed(): boolean {
    return this.transcript.meta.tiers.every(t => t.id === this.transcript.meta.defaultTier || (t.show === Object.keys(this.transcript.meta.speakers).forEach(sId => !t.show[sId])))
  }

  get areAllCollapsedSpeaker(): boolean {
    var r:any = {}
    Object.keys(this.transcript.meta.speakers).forEach(sId => {
      r[sId] = this.transcript.meta.tiers.every(t => t.id === this.transcript.meta.defaultTier || !t.show[sId])
    })
    return r
  }

  toggleTier(tier:TranscriptTier) {
    var stat:boolean = Object.keys(this.transcript.meta.speakers).every(s => tier.show[s] === true)
    Object.keys(this.transcript.meta.speakers).forEach(sId => {
      this.$set(tier.show, sId, !stat)
    })
  }

  toggleSpeakerTier(tier:TranscriptTier, sId:any) {
    this.$set(tier.show, sId, !tier.show[sId])
  }

  expandOrCollapse() {
    if (this.areAllExpanded) {
      return this.collapseAll()
    } else {
      return this.expandAll()
    }
  }

  openSpeakerAndTierSettings() {
    this.transcript.uiState.showTranscriptMetaSettings = true
  }

  expandAll() {
    this.$set(this.transcript.meta, 'tiers', this.transcript.meta.tiers.map((t) => {
      var r:any = {}
      Object.keys(this.transcript.meta.speakers).forEach(sId => {
        r[sId] = true
      })
      return { ...t, show: r }
    }))
  }

  expandSpeaker(sId:any) {
    this.transcript.meta.tiers.forEach(t => {
      this.$set(t.show, sId, true)
    })
  }

  collapseAll() {
    this.$set(this.transcript.meta, 'tiers', this.transcript.meta.tiers.map((t) => {
      return { ...t, show: {} }
    }))
  }

  collapseSpeaker(sId:any) {
    this.transcript.meta.tiers.forEach(t => {
      this.$set(t.show, sId, false)
    })
  }

  get theme() {
    if (this.settings.darkMode) {
      return {}
    } else {
      return { background: '#fafafa' }
    }
  }
}
