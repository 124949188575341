





























































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'

import settings from '../store/settings.store'
import { resourceAtUrlExists } from '../util'
import {
  ServerInformant,
  ServerSurvey,
  ServerTranscriptListItem,
  getAudioUrlFromServerNames,
  serverTokenTiers
} from '../service/backend-server.service'

import {
  ParsedExmaraldaXML,
  SpeakerTierImportable,
  importableToServerTranscript
} from '../service/backend-exmaralda.service'

import {
  TokenTierType
} from '@/types/transcript'

import ExmaraldaTierPreview from './ExmaraldaTierPreview.vue'
import DropFile from './DropFile.vue'
import ServerTranscriptInfoForm from './ServerTranscriptInfoForm.vue'
import { ProjectPresetName } from '@/presets'

@Component({
  components: {
    DropFile,
    ExmaraldaTierPreview,
    ServerTranscriptInfoForm
  }
})
export default class ExmaraldaImporter extends Vue {

  @Prop({ required: true }) importable!: ParsedExmaraldaXML
  @Prop({ default: [] }) transcripts!: ServerTranscriptListItem[]

  step = 1
  isBasicInfoValid = false
  areTiersValid = false
  settings = settings

  globalDefaultTier: TokenTierType|null = null
  transcriptName: string|null = this.importable.fileName.replace('.exb', '')
  selectedSurvey: ServerSurvey|null = null
  audioFileName: string|null = null
  audioFileUrl: string|null = null
  selectedAudioFile: File|null = null

  showMissingDefaultTierError = false
  isAnythingOrAllSelected: boolean|null = false
  visiblePreviewTier: string|null = null

  updateBasicInfo(args: { transcriptName: string, selectedSurvey: ServerSurvey, projectPreset: ProjectPresetName}) {
    this.transcriptName = args.transcriptName
    this.selectSurvey(args.selectedSurvey)
    settings.projectPreset = args.projectPreset
  }

  updateTierTokenTypeAndGlobalDefault(i: number, t: TokenTierType) {
    this.globalDefaultTier = t
  }

  isSpeakerTierPreviewShown(id: string): boolean {
    return this.visiblePreviewTier === id
  }

  toggleSpeakerTierPreview(id: string) {
    if (this.visiblePreviewTier === id) {
      this.visiblePreviewTier = null
    } else {
      this.visiblePreviewTier = id
    }
  }

  updateAudioFile(file: File|null) {
    this.selectedAudioFile = file
    this.audioFileName = file === null ? null : file.name
  }

  get possibleSpeakers(): ServerInformant[] {
    if (this.selectedSurvey !== null) {
      return this.selectedSurvey.FX_Informanten
    } else {
      return Object.keys(this.importable.speakers).map(s => ({
        Kuerzel: s,
        Vorname: null,
        Kuerzel_anonym: null,
        Name: null,
        pk: Number(_.uniqueId()) * -1
      }))
    }
  }

  get canContinue() {
    return (
      (this.step === 1 && this.isBasicInfoValid === true) ||
      (this.step === 2 && this.areTiersValid === true && this.isAnythingOrAllSelected !== false) ||
      (this.step === 3 &&
        this.audioFileName !== null &&
        this.transcriptName !== null &&
        (settings.backEndUrl !== null && this.selectedSurvey !== null) &&
        this.globalDefaultTier !== null
      )
    )
  }

  async selectSurvey(survey: ServerSurvey) {
    this.selectedSurvey = survey
    if (settings.backEndUrl !== null) {
      const audioFileUrl = getAudioUrlFromServerNames(survey.Audiofile, survey.Dateipfad, settings.backEndUrl)
      if (audioFileUrl !== null && await resourceAtUrlExists(audioFileUrl)) {
        this.audioFileName = survey.Audiofile + '.ogg'
        this.audioFileUrl = audioFileUrl
      } else {
        // not found.
      }
    }
  }

  getSelectedDefaultTierForSpeaker(toSpeaker: ServerInformant): SpeakerTierImportable[] {
    return this.importable.speakerTiers.filter(t => {
      return (
        t.toSpeaker !== null &&
        t.toSpeaker.pk === toSpeaker.pk &&
        t.toTierType === 'default' &&
        t.selectForImport === true
      )
    })
  }

  isDefaultTierSelectedForSpeaker(toSpeaker: ServerInformant|null): boolean {
    return toSpeaker !== null && this.getSelectedDefaultTierForSpeaker(toSpeaker).length === 1
  }

  speakerHasDuplicateDefaultTiers(toSpeaker: ServerInformant): boolean {
    return this.getSelectedDefaultTierForSpeaker(toSpeaker).length > 1
  }

  updateTierType(
    speakerTier: SpeakerTierImportable,
    toTierType: SpeakerTierImportable['toTierType'],
    i: number
  ) {
    this.importable.speakerTiers[i].toTierType = toTierType
  }

  isDuplicateDefaultTierForSpeaker(speakerTier: SpeakerTierImportable): boolean {
    if (
      speakerTier.selectForImport === false ||
      speakerTier.toTierType === 'default' ||
      speakerTier.toSpeaker === null
    ) {
      return false
    } else {
      return this.speakerHasDuplicateDefaultTiers(speakerTier.toSpeaker)
    }
  }

  updateAllSelections(v: boolean) {
    this.importable.speakerTiers = this.importable.speakerTiers.map((t) => {
      return { ...t, selectForImport: v }
    })
    this.updateIsEverythingSelected()
  }

  // FIXME: toSpeaker can be null
  tokenTiersAvailable(toSpeaker: ServerInformant) {

    const selectedTiersForSpeaker = this.importable.speakerTiers
      .filter(t =>
        t.selectForImport === true &&
        (t.toTierType === 'tokenized' || t.toTierType === 'default') &&
        t.toSpeaker !== null &&
        t.toSpeaker.pk === toSpeaker.pk
      )
      .map(t => t.tokenTierType)

    return serverTokenTiers.map(stt => {
      return { ...stt, disabled: selectedTiersForSpeaker.indexOf(stt.value) > -1 || this.globalDefaultTier === stt.value }
    })
  }

  get speakersWithMissingDefaultTier(): SpeakerTierImportable[] {
    return _(this.importable.speakerTiers)
      .filter(t => t.selectForImport === true && t.toSpeaker !== null)
      .groupBy(st => st.toSpeaker!.pk)
      .filter(tiersBySpeaker => tiersBySpeaker.filter(t => t.toTierType === 'default').length !== 1)
      .toArray()
      .flatten()
      .value()
  }

  everySelectedSpeakerHasExactlyOneDefaultTier(): boolean {
    return this.speakersWithMissingDefaultTier.length === 0
  }

  async validateAndNext() {
    if (this.step === 1) {
      if (this.isBasicInfoValid) {
        this.step = this.step + 1
      }
    } else if (this.step === 2) {
      if ((this.$refs.tierForm as any).validate()) {
        if (this.everySelectedSpeakerHasExactlyOneDefaultTier() && this.globalDefaultTier !== null) {
          this.step = this.step + 1
        } else {
          this.showMissingDefaultTierError = true
        }
      }
    } else if (this.step === 3) {
      if (
        this.transcriptName !== null &&
        (settings.backEndUrl !== null && this.selectedSurvey !== null) &&
        this.globalDefaultTier !== null
      ) {
        this.$emit(
          'finish',
          importableToServerTranscript(
            this.importable,
            this.transcriptName,
            this.selectedSurvey,
            this.globalDefaultTier
          ),
          this.selectedAudioFile,
          this.audioFileUrl
        )
      }
    }
  }

  updateIsEverythingSelected() {
    const every = _(this.importable.speakerTiers).every(t => t.selectForImport)
    if (every) {
      this.isAnythingOrAllSelected = true
    } else {
      const some = _(this.importable.speakerTiers).some(t => t.selectForImport)
      if (some) {
        this.isAnythingOrAllSelected = null
      } else {
        this.isAnythingOrAllSelected = false
      }
    }
  }

}
