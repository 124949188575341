





import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class HighlightRange extends Vue {

  @Prop({ required: true }) text!: string
  @Prop({ required: true }) start!: number
  @Prop({ required: true }) end!: number
  @Prop({ default: null }) truncate!: number|null

  updated() {
    // bad hack to fix a chrome bug
    // and invalidate the layout.
    const e = this.$refs.initial
    if (e instanceof HTMLElement) {
      e.style.direction = 'ltr'
      requestAnimationFrame(() => {
        e.style.direction = 'rtl'
      })
    }
  }

  get initial() {
    const highlightLength = this.end - this.start
    // const extendInEitherDirection = this.truncate !== null ? Math.floor((this.truncate - highlightLength) / 2) : 0
    const uncutInitial = this.text.substr(0, this.start)
    // const cutInitial = uncutInitial.substr(extendInEitherDirection * -1)
    // return cutInitial.length < uncutInitial.length ? '…' + cutInitial : cutInitial
    return uncutInitial
  }

  get highlighted() {
    return this.text.substr(this.start, this.end - this.start)
  }

  get tail() {
    // tslint:disable-next-line:max-line-length
    return this.text.substr(this.end)
  }
}
