


































import { Vue, Component, Prop } from 'vue-property-decorator'
import SpeakerSegmentTranscript from './SpeakerSegmentTranscript.vue'
import _ from 'lodash'

import settings from '../store/settings.store'
import { timeFromSeconds } from '@/util'
import store from '@/store'
import { TranscriptEvent } from '@/types/transcript'

@Component({
  components: {
    SpeakerSegmentTranscript
  }
})
export default class SegmentTranscript extends Vue {

  @Prop({ required: true }) event!: TranscriptEvent
  @Prop({ default: false }) isSelected!: boolean

  transcript = store.transcript!
  offsetWidth = 0


  toTime = timeFromSeconds
  settings = settings

  get speakerHeight() {
    var r:any = {}
    Object.keys(this.transcript.meta.speakers).forEach(sId => {
      r[sId] = (25 + (this.transcript.meta.tiers.filter(t => t.id === this.transcript.meta.defaultTier || t.show[sId] === true).length - 1) * 25.8) + 'px'
    })
    return r
  }

  get hasFragmentOfInAnyFirstToken(): boolean {
    return _(this.event.speakerEvents).some((speakerEvent) => {
      return speakerEvent.tokens[0] !== undefined && speakerEvent.tokens[0].fragmentOf !== null
    })
  }

  get isViewingEvent() {
    return (
      this.event !== null &&
      this.event !== undefined &&
      this.transcript.uiState.highlightedEventIds.indexOf(this.event.eventId) > -1
    )
  }

  selectAndScrollToEvent(e: TranscriptEvent) {
    if (!settings.lockScroll) {
      this.transcript.scrollToAudioEvent(e)
    }
    this.transcript.selectEvent(e)
  }

  selectOrDeselectEvent(e: TranscriptEvent) {
    this.transcript.selectOrDeselectEvent(e)
  }

  playEvent(e: TranscriptEvent) {
    if (this.transcript.audio) {
      this.transcript.audio.playEvent(e)
    }
  }

  selectEventRange(e: TranscriptEvent) {
    this.transcript.selectEventRange(e)
  }

}
