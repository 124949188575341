











import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @Prop() label?: string
  @Prop({ required: true }) value!: boolean
  @Prop({ default: false }) disabled!: boolean
}
