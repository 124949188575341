








import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Main extends Vue {
  mounted() {
    const isChromium = (window as any).chrome !== undefined
    if (!isChromium) {
      alert('Transcribe currently only supports recent versions of Google Chrome, Chromium and Microsoft Edge browsers. Using other browsers might lead to unexpected or erroneous results.')
    }
  }
}
