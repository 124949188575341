import { render, staticRenderFns } from "./KeyboardShortcut.vue?vue&type=template&id=4caeb9b0&scoped=true&"
import script from "./KeyboardShortcut.vue?vue&type=script&lang=ts&"
export * from "./KeyboardShortcut.vue?vue&type=script&lang=ts&"
import style0 from "./KeyboardShortcut.vue?vue&type=style&index=0&id=4caeb9b0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4caeb9b0",
  null
  
)

export default component.exports