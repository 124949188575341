var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'selected': _vm.isSelected,
  'segment': true,
  'theme--dark': _vm.settings.darkMode,
  'fragment-of': _vm.hasFragmentOfInAnyFirstToken
}},[_c('div',{class:{
      'time': true,
      'viewing': _vm.isViewingEvent
    },staticStyle:{"outline":"0"},attrs:{"tabindex":"-1"},on:{"dblclick":function($event){return _vm.playEvent(_vm.event)},"mousedown":[function($event){if(!$event.metaKey){ return null; }$event.stopPropagation();return _vm.selectOrDeselectEvent(_vm.event)},function($event){if(!$event.ctrlKey){ return null; }$event.stopPropagation();return _vm.selectOrDeselectEvent(_vm.event)},function($event){if(!$event.shiftKey){ return null; }return _vm.selectEventRange(_vm.event)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.selectAndScrollToEvent(_vm.event)}]}},[_vm._v(" "+_vm._s(_vm.toTime(_vm.event.startTime))+" - "+_vm._s(_vm.toTime(_vm.event.endTime))+" ")]),_vm._l((this.transcript.meta.speakers),function(speaker,speakerKey){return _c('div',{key:speakerKey,staticClass:"speaker-segment",style:({ height: _vm.speakerHeight[speakerKey] })},[_c('speaker-segment-transcript',{staticClass:"tokens",attrs:{"event":_vm.event,"speaker":speakerKey}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }