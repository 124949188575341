import { render, staticRenderFns } from "./SegmentBox.vue?vue&type=template&id=5293fea7&scoped=true&"
import script from "./SegmentBox.vue?vue&type=script&lang=ts&"
export * from "./SegmentBox.vue?vue&type=script&lang=ts&"
import style0 from "./SegmentBox.vue?vue&type=style&index=0&id=5293fea7&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5293fea7",
  null
  
)

export default component.exports