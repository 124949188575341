





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { SpeakerTierImportable } from '../service/backend-exmaralda.service'
import { RecycleScroller } from 'vue-virtual-scroller'
import _ from 'lodash'
import { timeFromSeconds } from '@/util'

@Component({
  components: {
    RecycleScroller
  }
})
export default class ExmaraldaTierPreview extends Vue {
  @Prop({ required: true }) tier!: SpeakerTierImportable
  toTime = timeFromSeconds
  get tierEventsWithId() {
    return this.tier.events.map(te => {
      return { ...te, id: _.uniqueId() }
    })
  }
}
