

























































import { Vue, Component, Prop } from 'vue-property-decorator'

import SegmentTranscript from './SegmentTranscript.vue'
import { RecycleScroller } from 'vue-virtual-scroller'
import Checkbox from './helper/Checkbox.vue'
import Dropdown from './helper/Dropdown.vue'

import settings from '../store/settings.store'
import { WarningEvent } from '../service/warnings.service'
import { timeFromSeconds } from '@/util'
import store from '@/store'

@Component({
  components: {
    SegmentTranscript,
    RecycleScroller,
    Checkbox,
    Dropdown
  }
})
export default class WarningList extends Vue {

  @Prop({ default: [] }) warnings!: WarningEvent[]

  transcript = store.transcript!
  toTime = timeFromSeconds
  selectedWarning: WarningEvent|null = null
  settings = settings
  eventGapOptions = [.1, .25, .5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 10]

  showEventIfExists(e: WarningEvent) {
    console.log('WarningEvent', JSON.parse(JSON.stringify(e)))
    const i = this.transcript.findEventIndexById(e.event.eventId)
    this.selectedWarning = e
    if (i > -1) {
      this.transcript.selectEvent(e.event)
      this.transcript.scrollToAudioEvent(e.event)
      this.transcript.scrollToTranscriptEvent(e.event)
    }
  }
}
