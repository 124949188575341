



















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import editor from './Editor.vue'
import sidebar from './Sidebar.vue'
import exmaraldaImporter from './ExmaraldaImporter.vue'

// import * as socket from '../service/socket'
import diskService, { LocalTranscriptListItem } from '../service/disk.service'

import store from '@/store'
import settings from '@/store/settings.store'
import { fileToTextAndName } from '@/util'

import {
  ServerTranscript,
  ServerTranscriptListItem,
  getServerTranscripts,
  mergeServerTranscript,
  serverTranscriptToLocal,
  getMetadataFromServerTranscript
} from '@/service/backend-server.service'

import * as Sentry from '@sentry/browser'

import {
  ParsedExmaraldaXML,
  exmaraldaToImportable
} from '@/service/backend-exmaralda.service'

import FIcon from './helper/FIcon.vue'
import Transcript from '@/classes/transcript.class'

const SEARCH_TERM_PREFIX = 'transcribe_app_search_term'

@Component({
  components: {
    editor,
    exmaraldaImporter,
    sidebar,
    FIcon
  }
})
export default class App extends Vue {

  store = store
  settings = settings

  backEndUrls = [
    {
      text: 'On This Computer',
      value: null
    },
    {
      text: 'dioedb.dioe.at',
      value: 'https://dioedb.dioe.at'
    },
    {
      text: 'dissdb.dioe.at',
      value: 'https://dissdb.dioe.at'
    },
    {
      text: 'dissdb-test.dioe.at',
      value: 'https://dissdb-test.dioe.at'
    },
    {
      text: 'localhost:8000 (development)',
      value: 'http://localhost:8000'
    },
    {
      text: 'dioedb.demo.dioe.at',
      value: 'https://dioedb.demo.dioe.at'
    }
  ]

  searchTerm = localStorage.getItem(this.searchTermStorageKey) || ''
  importingLocalFile = false
  loadingTranscriptId: number|null = null
  loggedIn: boolean = false
  importableExmaraldaFile: ParsedExmaraldaXML|null = null
  errorMessage: string|null = null
  isLoadingBackendUrl = false

  openLoginSite(host: string|null) {
    if (host !== null) {
      window.open(host + '/login/', '_blank')
      const that = this
      window.addEventListener('focus', async function onRefocus() {
        window.removeEventListener('focus', onRefocus)
        await that.loadTranscriptList(host)
      })
    }
  }

  @Watch('searchTerm')
  onChangeSearchTerm(v: string|null) {
    localStorage.setItem(this.searchTermStorageKey, v || '')
  }

  @Watch('settings.backEndUrl', { immediate: true })
  async onUpdateBackEndUrl(url: string|null) {
    if (url !== null) {
      this.connectToBackend(url)
    } else {
      this.useLocalTranscripts()
    }
    this.searchTerm = localStorage.getItem(this.searchTermStorageKey) || ''
  }

  get searchTermStorageKey(): string {
    return SEARCH_TERM_PREFIX + '__' + (settings.backEndUrl || '')
  }

  useLocalTranscripts() {
    store.allTranscripts = diskService.localTranscripts
  }

  async connectToBackend(url: string|null) {
    this.isLoadingBackendUrl = true
    settings.backEndUrl = url
    this.updateTokenTypePreset()
    await this.loadTranscriptList(url)
    this.isLoadingBackendUrl = false
    console.log('connect to backend', url, 'update server', process.env.UPDATE_SERVER)
    // FIXME:
    // if (process.env.UPDATE_SERVER !== undefined) {
    //   socket.connectToSocket('https://dioedb.dioe.at')
    //   // socket.connectToSocket('http://localhost:3000')
    //   socket.onMessage((m) => {
    //     if (m.type === 'list_open_transcripts' && this.transcriptList !== null) {
    //       this.transcriptList = this.transcriptList.map(t => {
    //         return {
    //           ...t,
    //           users: m.transcripts.filter(ts => ts.transcript_id === t.pk).map(ts => ts.user.name),
    //           locked: m.transcripts.some(ts => ts.transcript_id === t.pk && ts.app === 'anno')
    //         }
    //       })
    //     }
    //   })
    // }
  }

  async onDropFile(e: DragEvent) {
    if (e instanceof DragEvent && e.dataTransfer !== null) {
      if (
        e.dataTransfer.files !== null &&
        e.dataTransfer.files.length === 1
      ) {
        console.log(e.dataTransfer.items)
        const fh = await e.dataTransfer.items[0].getAsFileSystemHandle()
        if (fh?.kind === 'file') {
          this.openFile(fh)
        }
      }
    }
  }

  // FIXME:
  // Here, we’re picking the project preset based on the backend url.
  // This is insanely hacky.
  // The right way to do it would be to store the project part (PP) in the
  // DB alongside the transcript.
  async updateTokenTypePreset() {
    if (settings.backEndUrl !== null && settings.backEndUrl !== undefined && settings.backEndUrl.includes('dioedb')) {
      // settings.projectPreset = 'PP03'
    } else if (settings.backEndUrl !== null && settings.backEndUrl !== undefined && settings.backEndUrl.includes('dissdb')) {
      settings.projectPreset = 'dissDB'
    }
  }

  async loadTranscriptList(url: string|null) {
    console.log('url when loading', url)
    if (url === null) {
      store.allTranscripts = await diskService.loadTranscriptList()
    } else {
      try {
        this.errorMessage = null
        const res = await getServerTranscripts(url)
        if (res.transcripts !== undefined) {
          this.loggedIn = true
          store.allTranscripts = res.transcripts
        } else if ((res as any).error === 'login') {
          this.loggedIn = false
        }
      } catch (e) {
        this.loggedIn = false
        store.allTranscripts = []
        this.errorMessage = 'could not load transcripts from back end.'
      }
    }
  }

  get filteredTranscriptList(): ServerTranscriptListItem[] {
    if (store.allTranscripts !== null) {
      if (this.searchTerm !== null) {
        return store.allTranscripts.filter(v => {
          return v.n.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        })
      } else {
        return store.allTranscripts
      }
    } else {
      return []
    }
  }

  async loadImportedTranscript(t: ServerTranscript, audioData: File|null, audioUrl?: string) {
    mergeServerTranscript(t)
    const meta = getMetadataFromServerTranscript(t)
    const defaultTier = meta.defaultTier || 'text'
    const events = serverTranscriptToLocal(t, defaultTier)
    store.transcript = new Transcript({ events, meta: { ...meta, lockedTokens: [] } }, audioData || audioUrl)
    console.log('loadImportedTranscript', {t, audioData, audioUrl, meta, defaultTier, events, 'store.transcript': store.transcript})
    this.importableExmaraldaFile = null
  }

  async openExmaraldaFile(f: File) {
    this.importingLocalFile = true
    const { t, n } = await fileToTextAndName(f)
    this.importableExmaraldaFile = exmaraldaToImportable(n, t)
    this.importingLocalFile = false
  }

  async openFileDialog() {
    let availableFileTypes = {
      'application/zip': '.transcript',
      'audio/ogg': '.ogg'
    } as any
    if (settings.backEndUrl !== null) {
      availableFileTypes['text/xml'] = '.exb'
    }
    const f = await diskService.openFile(availableFileTypes)
    this.openFile(f)
  }

  async openFile(f: FileSystemFileHandle) {
    if (f.name.endsWith('.transcript')) {
      this.importingLocalFile = true
      store.transcript = new Transcript(f)
      this.importingLocalFile = false
    } else if (f.name.endsWith('.exb')) {
      this.openExmaraldaFile(await f.getFile())
    } else if (f.name.endsWith('.ogg')) {
      store.transcript = new Transcript(await f.getFile())
    } else {
      alert('Unrecognized File type.')
    }
  }

  initializeEmptyTranscript() {
    store.status = 'new'
    store.transcript = new Transcript()
  }

  async openTranscript(t: LocalTranscriptListItem|ServerTranscriptListItem) {
    if ('fileHandle' in t) {
      if (await diskService.getPermission(t.fileHandle)) {
        store.transcript = new Transcript(t.fileHandle)
        Sentry.setContext('transcript', {
          name: t.fileHandle.name,
          id: -1,
          type: 'local'
        })
      }
    } else {
      Sentry.setContext('transcript', {
        name: t.n,
        id: t.pk,
        type: 'remote'
      })
      this.loadRemoteTranscript(t)
    }
  }

  async loadRemoteTranscript(t: ServerTranscriptListItem) {
    // socket.sendMessage({
    //   type: 'open_transcript',
    //   transcript_id: t.pk,
    //   app: 'transcribe'
    // })
    if (settings.backEndUrl !== null) {
      store.transcript = new Transcript({
        id: t.pk,
        backEndUrl: settings.backEndUrl
      })
    }
  }
}
