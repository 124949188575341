










import { Vue, Component, Prop } from 'vue-property-decorator'
import { KeyboardAction, convertKey, convertModifier } from '@/service/keyboard.service'
@Component
export default class KeyboardShortcut extends Vue {
  @Prop({ required: true }) value!: KeyboardAction
  convertKey = convertKey
  convertModifier = convertModifier
}
