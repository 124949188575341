













































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import settings from '../store/settings.store'
import SettingsKeyboardShortcuts from './SettingsKeyboardShortcuts.vue'
import SettingsTokenTypes from './SettingsTokenTypes.vue'
import { makeGradient } from '../lib/gradient'
import { Chrome as ColorPicker } from 'vue-color'
import TranscriptAudio from '@/classes/transcript-audio.class'
import Transcript from '@/classes/transcript.class'

@Component({
  components: {
    SettingsKeyboardShortcuts,
    SettingsTokenTypes,
    ColorPicker
  }
})
export default class Settings extends Vue {

  @Prop({ default: false }) show!: boolean
  @Prop({ default: null }) audio!: TranscriptAudio
  @Prop({ default: null }) transcript!: Transcript
  settings = settings
  activeTab = null

  audioInfos() {
    var devData = {
      settings: settings,
      audio: this.audio ? JSON.parse(JSON.stringify({
        currentTime: this.audio.currentTime,
        duration: this.audio.duration,
        fileSize: this.audio.fileSize,
        isPaused: this.audio.isPaused,
        playAllFromTime: this.audio.playAllFromTime,
        url: this.audio.url
      })) : null
    }
    console.log(devData)
    navigator.clipboard.writeText(JSON.stringify(devData, null, 2)).then(function() {
      alert('Copy DevData to Clipboard!')
    }, function(err) {
      alert('Can\'t copy DevData to clipboard!\nThe DevData are available in the console.')
      console.error('Async: Could not copy: ', err)
    })
  }

  transcriptInfos() {
    var transcriptData = JSON.parse(JSON.stringify({
      key: this.transcript.key,
      meta: this.transcript.meta,
      uiState: this.transcript.uiState,
      events: this.transcript.events
    }))
    console.log(transcriptData, this.transcript)
    navigator.clipboard.writeText(JSON.stringify(transcriptData, null, 2)).then(function() {
      alert('Copy TranscriptData to Clipboard!')
    }, function(err) {
      alert('Can\'t copy TranscriptData to clipboard!\nThe TranscriptData are available in the console.')
      console.error('Async: Could not copy: ', err)
    })
  }

  updateGradient(i: number, c: any) {
    this.settings.spectrogramColors[i].c = [c.rgba.r, c.rgba.g, c.rgba.b, c.rgba.a]
    const g = makeGradient(this.settings.spectrogramColors)
    this.settings.spectrogramGradient = g
  }

}
