


















































import { Vue, Component } from 'vue-property-decorator'
import settings from '../store/settings.store'
import { modifierKeys, specialKeys, alphaNumericKeys } from '../service/keyboard.service'
import _ from 'lodash'

@Component
export default class SettingsKeyboardShortcuts extends Vue {

  settings = settings

  get groups() {
    return _(settings.keyboardShortcuts).groupBy('group').value()
  }

  get keys() {
    return alphaNumericKeys.concat(specialKeys).map(k => ({ text: k.displayName, value: k.jsName }))
  }

  get modifierKeys() {
    return modifierKeys.map(k => ({ text: k.displayName, value: k.name }))
  }
}
