



















import { Vue, Component, Prop } from 'vue-property-decorator'
import { TranscriptEvent } from '@/types/transcript'
import bus, { BusEvent } from '../service/bus'
import settings from '../store/settings.store'
import _ from 'lodash'
import { requestFrameAsync, timeFromSeconds } from '../util'

@Component
export default class Scrollbar extends Vue {

  // global events to listen to
  @Prop() updateOn!: BusEvent|BusEvent[]
  @Prop({ required: true }) maxTime!: number

  settings = settings

  overviewTimeWidth = 70 // width of the time preview tooltip above the overview waveform
  isDragging = false

  mounted() {
    this.listenToEvents(this.updateOn)
  }

  listenToEvents(es = this.updateOn) {
    if (Array.isArray(es)) {
      es.forEach((e) => bus.$on(e, this.moveThumbToTime))
    } else {
      bus.$on(es, this.moveThumbToTime)
    }
  }

  unlisten(es = this.updateOn) {
    if (Array.isArray(es)) {
      es.forEach((e) => bus.$off(e, this.moveThumbToTime))
    } else {
      bus.$off(es, this.moveThumbToTime)
    }
  }

  beforeDestroy() {
    this.unlisten(this.updateOn)
  }

  async getOffsets(x: number) {
    const thumb = this.$refs.scrollbarThumb
    const track = this.$refs.scrollbarTrack
    if (track instanceof HTMLElement && thumb instanceof HTMLElement) {
      await requestFrameAsync()
      const scrollbarWidth = track.offsetWidth - thumb.offsetWidth
      const offset = x - track.getBoundingClientRect().left
      const time = Math.max(0, Math.min(offset / scrollbarWidth * this.maxTime, this.maxTime))
      const limitedOffset = Math.max(0, Math.min(offset, scrollbarWidth))
      return { time, limitedOffset }
    } else {
      throw new Error('Elements not found')
    }
  }

  moveThumbToTime(t: number|TranscriptEvent) {
    const time = typeof t === 'number' ? t : t.startTime
    requestAnimationFrame(() => {
      const thumb = this.$refs.scrollbarThumb
      const track = this.$refs.scrollbarTrack
      if (track instanceof HTMLElement && thumb instanceof HTMLElement) {
        const scrollbarWidth = track.offsetWidth - thumb.offsetWidth
        const offset = time / this.maxTime * scrollbarWidth
        const limitedOffset = Math.max(0, Math.min(offset, scrollbarWidth))
        requestAnimationFrame(() => {
          thumb.style.transform = `translate3d(${ limitedOffset }px, 0, 0)`
        })
      }
    })
  }

  startDrag(e: MouseEvent) {
    this.handleDrag(e)
    this.isDragging = true
    document.addEventListener('mousemove', this.handleDrag)
    document.addEventListener('mouseup', this.endDrag)
  }

  async handleDrag(ev: MouseEvent) {
    this.updateOverviewTime(ev)
    const thumb = this.$refs.scrollbarThumb
    const track = this.$refs.scrollbarTrack
    const { time, limitedOffset } = await this.getOffsets(ev.clientX)
    this.$emit('scroll', time)
    requestAnimationFrame(() => {
      (thumb as HTMLElement).style.transform = `translate3d(${ limitedOffset }px, 0, 0)`
    })
  }

  async endDrag(ev: MouseEvent) {
    this.isDragging = false
    document.removeEventListener('mousemove', this.handleDrag)
    document.removeEventListener('mouseup', this.endDrag)
    const { time } = await this.getOffsets(ev.x)
    this.$emit('scrollend', time)
  }

  async updateOverviewTime(e: MouseEvent) {
    const timer = this.$refs.overviewTime
    if (timer instanceof HTMLElement) {
      const { time, limitedOffset } = await this.getOffsets(e.x)
      if (!_.isNaN(time)) {
        requestAnimationFrame(() => {
          timer.innerHTML = timeFromSeconds(time)
          timer.style.transform = `translate3d(${ limitedOffset }px, 0, 0)`
        })
      }
    }
  }

}
