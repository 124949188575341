






































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Chrome as ColorPicker } from 'vue-color'
import _ from 'lodash'

import settings from '../store/settings.store'
import presets from '../presets'

@Component({
  components: {
    ColorPicker
  }
})
export default class SettingsTokenTypes extends Vue {

  settings = settings
  presets = presets

  isValidRegEx(e: string) {
    try {
      const x = new RegExp(e)
      return true
    } catch (e) {
      return false
    }
  }

  get projectPresetNames(): string[] {
    return _.map(this.presets, (p, name) => name)
  }

  updateRegEx(...args: any[]) {
    console.log(args)
  }

  updateBracket(listIndex: number, regex: string, bracketIndex: number) {
    console.log(listIndex, regex, bracketIndex)
  }

  updateWaveFormColor(...args: any[]) {
    console.log(args)
  }

}
