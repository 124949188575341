































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import SegmentTranscript from './SegmentTranscript.vue'
import { TranscriptEvent } from '@/types/transcript'
import settings from '../store/settings.store'
import store from '@/store'
import _ from 'lodash'

interface Square {
  event: TranscriptEvent
  left: number
  right: number
}

@Component({
  components: {
    SegmentTranscript
  }
})
export default class SearchResults extends Vue {

  readonly resultMarkerColor = 'yellow'

  menuX = 0
  menuY = 0
  hoveredEvent: TranscriptEvent|null = null
  transcript = store.transcript!
  settings = settings
  context: null|CanvasRenderingContext2D = null
  squares: Square[] = []

  @Watch('settings.showDrawer')
  onChangeShowDrawer() {
    this.drawResults()
  }

  mounted() {
    this.context = (this.$refs.myCanvas as HTMLCanvasElement).getContext('2d')
    window.addEventListener('resize', this.debouncedDrawResults)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedDrawResults)
  }

  getSquaresAtPosition(x: number): Square[] {
    return this.squares.filter((s) => x >= s.left && x <= s.right)
  }

  onClick() {
    if (this.hoveredEvent !== null) {
      this.transcript.scrollToAudioEvent(this.hoveredEvent)
      this.transcript.scrollToTranscriptEvent(this.hoveredEvent)
      this.transcript.selectEvent(this.hoveredEvent)
    }
  }

  onMouseMove(e: MouseEvent) {
    const rect = (e.target as HTMLCanvasElement).getBoundingClientRect()
    const x = e.clientX - rect.left
    const squares = this.getSquaresAtPosition(x)
    if (squares.length > 0) {
      this.menuX = squares[0].left
      this.menuY = rect.y
      this.hoveredEvent = squares[0].event
    }
  }

  drawResults() {
    this.squares = []
    if (this.context !== null) {
      const width = this.$el.clientWidth
      const duration = this.transcript.audio?.duration || 0
      // setting the width clears the canvas.
      this.context.canvas.width = width
      // batched drawing setup
      this.context.beginPath()
      this.context.fillStyle = this.resultMarkerColor
      for (const r of this.transcript.uiState.searchResults) {
        const left = (((r.event.startTime / duration) * width) + .5) | 0
        // draw the rectangle
        this.context.rect(left, 0, 3, 10)
        this.squares.push({
          event: r.event,
          left: left,
          right: left + 3
        })
      }
      this.context.fill()
    }
  }

  debouncedDrawResults = _.debounce(this.drawResults, 300)

  @Watch('transcript.uiState.searchResults')
  onResultsChange() {
    this.drawResults()
  }
}
