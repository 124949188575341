


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import presets from '../presets'
import settings from '../store/settings.store'
import { ServerTranscriptListItem, ServerSurvey, getSurveys } from '../service/backend-server.service'
import _ from 'lodash'
import { clone } from '../util'

@Component
export default class ServerTranscriptInfoForm extends Vue {

  // the validity of the data. for use with v-model
  @Prop({ default: false }) value!: boolean
  @Prop({ default: [] }) transcripts!: ServerTranscriptListItem[]
  @Prop({ default: null }) name!: string|null
  @Prop({ default: null }) survey!: ServerSurvey|null

  transcriptName = this.name
  isBasicInfoValid = false
  presets = presets
  settings = settings
  surveys: ServerSurvey[]|null = null
  selectedSurvey = clone(this.survey)

  async onUpdateSurvey() {
    await this.$nextTick()
    if (this.selectedSurvey !== null && (this.transcriptName === null || this.transcriptName === '')) {
      this.transcriptName = this.selectedSurvey.Audiofile.replace('.ogg', '')
      await this.$nextTick()
    }
    this.emitUpdateIfValid()
  }

  async emitUpdateIfValid() {
    if (this.isBasicInfoValid) {
      this.$emit('update', {
        transcriptName: this.transcriptName,
        selectedSurvey: this.selectedSurvey,
        projectPreset: this.settings.projectPreset
      })
      this.$emit('input', true)
    } else {
      this.$emit('input', false)
    }
  }

  surveyFilter(item: ServerSurvey, queryText: string): boolean {
    const l = queryText.toLocaleLowerCase()
    return (
      (item.OrtString !== 'None' && item.OrtString.toLocaleLowerCase().includes(l)) ||
      item.Audiofile.toLocaleLowerCase().includes(l) ||
      item.Datum.includes(l)
    )
  }

  async mounted() {
    if (this.settings.backEndUrl !== null) {
      this.surveys = await getSurveys(this.settings.backEndUrl)
    } else {
      this.surveys = []
    }
    this.emitUpdateIfValid()
  }

  get projectPresetNames(): string[] {
    return _.map(this.presets, (p, name) => name)
  }

  isTranscriptNameUnique(n: string|null): boolean {
    return this.transcripts.find(t => t.n === n) === undefined
  }
}
