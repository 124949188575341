







































































import { Vue, Component } from 'vue-property-decorator'
import { TranscriptTier, TokenTierType, LocalTranscriptSpeakers } from '@/types/transcript'
import ServerTranscriptInfoForm from './ServerTranscriptInfoForm.vue'
import SpeakerTierEditor from './SpeakerTierEditor.vue'
import {
  ServerSurvey,
  getAudioUrlFromServerNames,
  serverTranscript,
  ServerTranscript,
  surveyToServerTranscriptSurvey,
  surveyToServerTranscriptInformants,
  serverTokenTiers,
  mergeServerTranscript,
  getMetadataFromServerTranscript,
  serverTranscriptToLocal,
  serverTranscriptSurveyToSurvey
} from '@/service/backend-server.service'
import _ from 'lodash'
import { computeTokenTypesForEvents } from '@/service/token-types.service'
import { resourceAtUrlExists, clone } from '@/util'
import { ProjectPresetName } from '@/presets'
import store from '@/store/'
import TranscriptAudio from '@/classes/transcript-audio.class'
import settings from '@/store/settings.store'

interface BasicInfos {
  transcriptName: string|null
  selectedSurvey: ServerSurvey|null
  projectPreset: ProjectPresetName|null
}

@Component({
  components: {
    ServerTranscriptInfoForm,
    SpeakerTierEditor
  }
})
export default class TranscriptSettings extends Vue {

  transcript = store.transcript!
  isBasicInfoValid = true
  serverTranscript = serverTranscript
  serverTokenTiers = serverTokenTiers
  defaultTier: TokenTierType = 'ortho'
  tiers: TranscriptTier[] = clone(this.transcript.meta.tiers)
  basicInfos: BasicInfos = {
    transcriptName: this.transcript.meta.transcriptName,
    selectedSurvey: serverTranscript?.aEinzelErhebung
      ? serverTranscriptSurveyToSurvey(serverTranscript?.aEinzelErhebung)
      : null,
    projectPreset: settings.projectPreset
  }

  async updateBasicInfos(args: BasicInfos) {
    this.basicInfos = args
    this.transcript.meta.transcriptName = args.transcriptName
    if (args.selectedSurvey !== null) {
      this.transcript.meta.speakers = _(args.selectedSurvey.FX_Informanten)
        .keyBy('pk')
        .mapValues(i => ({ k: i.Kuerzel, ka: i.Kuerzel_anonym || '', searchInSpeaker: true }))
        .value()
    }
  }

  createBasicServerTranscript(args: BasicInfos): ServerTranscript|null {
    if (args.selectedSurvey !== null && args.transcriptName !== null) {
      return {
        aNr: 0,
        nNr: 0,
        aPk: '-1',
        aEinzelErhebung: args.selectedSurvey === null ? undefined : surveyToServerTranscriptSurvey(args.selectedSurvey),
        aInformanten: args.selectedSurvey === null
          ? this.transcript.meta.speakers
          : surveyToServerTranscriptInformants(args.selectedSurvey),
        aTiers: this.transcript.meta.tiers.reduce((m, e) => {
          m[e.id] = { tier_name: e.name }
          return m
        }, {} as ServerTranscript['aTiers']),
        aTokens: {},
        aEvents: [],
        aTranskript: {
          default_tier: this.defaultTier,
          n: args.transcriptName,
          pk: -1,
          ut: 'now'
        }
      }
    } else {
      return null
    }
  }

  async confirm() {
    if (
      this.basicInfos !== null &&
      this.isBasicInfoValid
    ) {
      this.transcript.meta.tiers = this.tiers
      this.transcript.meta.transcriptName = this.basicInfos.transcriptName
      // it’s a new transcript
      if (
        this.serverTranscript?.aTranskript?.pk === undefined &&
        this.basicInfos.selectedSurvey !== null
      ) {
        const st = this.createBasicServerTranscript(this.basicInfos)
        if (st !== null) {
          mergeServerTranscript(st)
          this.transcript.meta = { ...getMetadataFromServerTranscript(st), lockedTokens: this.transcript.meta.lockedTokens }
          const events = serverTranscriptToLocal(st, this.transcript.meta.defaultTier)
          this.transcript.events = computeTokenTypesForEvents(
            events,
            this.transcript.meta.defaultTier || 'text',
            Object.keys(this.transcript.meta.speakers)
          )
          if (settings.backEndUrl !== null) {
            const audioFileUrl = getAudioUrlFromServerNames(
              this.basicInfos.selectedSurvey.Audiofile,
              this.basicInfos.selectedSurvey.Dateipfad,
              settings.backEndUrl
            )
            if (audioFileUrl !== null && (await resourceAtUrlExists(audioFileUrl))) {
              this.transcript.audio = new TranscriptAudio(audioFileUrl)
            }
          }
        }
      }
      this.transcript.uiState.showTranscriptMetaSettings = false
    }
  }

  updateSpeakers(ss: LocalTranscriptSpeakers) {
    this.transcript.meta.speakers = ss
  }
}

