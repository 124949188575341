

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import store from '@/store'
import { timeFromSeconds, timeToSeconds } from '@/util'
@Component
export default class TimePicker extends Vue {

  transcript = store.transcript!
  toTime = timeFromSeconds

  mounted() {
    const r = this.$refs.timeSelection
    if (r instanceof HTMLElement) {
      r.focus()
    }
  }

  // FIXME: this seems to screw up scrolling afterwards
  jumpToTime(time: string) {
    this.transcript.deselectEvents()
    const t = timeToSeconds(time)
    requestAnimationFrame(() => {
      if (this.transcript.audio !== null) {
        this.transcript.scrollToAudioTime(t)
        this.transcript.audio.scrubAudio(t)
      }
    })
  }

}
