


















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { TranscriptTier, LocalTranscriptSpeakers, LocalTranscriptSpeaker } from '@/types/transcript'
import store from '@/store'
import _ from 'lodash'
import Transcript from '@/classes/transcript.class'
import settings from '@/store/settings.store'

@Component
export default class SpeakerTierEditor extends Vue {

  @Prop({ default: {} }) speakers!: LocalTranscriptSpeakers
  @Prop({ default: [] }) tiers!: TranscriptTier[]

  settings = settings
  enteringTierName = ''
  enteringSpeakerName = ''

  get speakersLength() {
    return _(this.speakers).size()
  }

  isUniqueSpeakerName(name: string) {
    return _(this.speakers).find(s => s.ka === name) === undefined
  }

  isUniqueTierName(name: string) {
    return this.tiers.find(t => t.name === name) === undefined
  }

  isValidTierName(name: string) {
    return name.trim() !== '' && this.isUniqueTierName(name)
  }

  isValidSpeakerName(name: string) {
    return name.trim() !== '' && this.isUniqueSpeakerName(name)
  }

  speakerFromName(name: string): LocalTranscriptSpeaker {
    return {
      ka: name,
      k: name,
      searchInSpeaker: true
    }
  }

  tierFromName(name: string): TranscriptTier {
    return {
      name,
      id: String(Transcript.makeEventTierId()),
      type: 'freeText',
      show: {},
      searchInTier: true
    }
  }

  addSpeaker() {
    if (this.isValidTierName(this.enteringSpeakerName)) {
      this.$emit('update:speakers', {
        ...this.speakers,
        [ Transcript.makeSpeakerId() ]: this.speakerFromName(this.enteringSpeakerName)
      })
      this.enteringSpeakerName = ''
    }
  }

  addTier() {
    if (this.isValidTierName(this.enteringTierName)) {
      this.$emit('update:tiers', [ ...this.tiers, this.tierFromName(this.enteringTierName) ])
      this.enteringTierName = ''
    }
  }

  async removeEventTier(id: string) {
    this.$emit('update:tiers', this.tiers.filter(t => !(t.type === 'freeText' && t.id === id)))
  }

  async removeSpeaker(key: string) {
    await this.$nextTick()
    this.$emit('update:speakers', _.omit(this.speakers, key))
  }

  get eventTiers(): TranscriptTier[] {
    return this.tiers.filter(t => t.type === 'freeText')
  }

}
