










import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Dropdown extends Vue {
  @Prop({ required: true }) value!: any
  @Prop({ default: [] }) items!: any[]
  @Prop() stringify?: (e: any) => string
}
