






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class DropFile extends Vue {

  @Prop({ default: null }) initialFileName: string|null = null
  fileName = this.initialFileName
  file: File|null = null

  @Watch('initialFileName')
  onUpdateInitialFileName(n: string|null) {
    this.fileName = n
  }

  highlight(e: DragEvent|Event) {
    if (e instanceof DragEvent && e.dataTransfer !== null) {
      console.log(e.dataTransfer.files[0], e.dataTransfer.items[0], e.dataTransfer.types[0]);
      (e.target as Element).classList.add('highlight')
    }
  }

  unhighlight(e: Event) {
    (e.target as Element).classList.remove('highlight')
  }

  useAudioFile(e: DragEvent) {
    if (
      e instanceof DragEvent &&
      e.dataTransfer !== null &&
      e.dataTransfer.files[0] !== undefined
    ) {
      this.unhighlight(e)
      if (e.dataTransfer.files[0].name.substr(-4).toLowerCase() === '.ogg') {
        this.file = e.dataTransfer.files[0]
        this.fileName = this.file.name
        this.$emit('update', this.file)
      } else {
        alert('File type has to be OGG!')
      }
    }
  }

  resetForm() {
    this.file = null
    this.fileName = null
    this.$emit('update', this.file)
  }

  openFileDialog() {
    const x = document.createElement('input')
    x.type = 'file'
    x.accept = '.ogg'
    x.addEventListener('change', async (e) => {
      if (x.files !== null) {
        if (x.files[0].name.substr(-4).toLowerCase() === '.ogg') {
          this.file = x.files[0]
          this.fileName = this.file.name
          this.$emit('update', this.file)
        } else {
          alert('File type has to be OGG!')
        }
      }
    })
    x.click()
  }
}
