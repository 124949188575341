


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import settings from '../store/settings.store'

@Component
export default class ScrollLockButton extends Vue {
  @Prop({ default: false }) value!: boolean
  settings = settings
}
