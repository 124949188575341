









































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { displayKeyboardAction, KeyboardAction } from '../service/keyboard.service'
import KeyboardShortcut from '@/components/helper/KeyboardShortcut.vue'
import settings from '@/store/settings.store'
import store from '@/store'
import _ from 'lodash'

@Component({
  components: {
    KeyboardShortcut
  }
})
export default class Actions extends Vue {

  settings = settings
  transcript = store.transcript!

  displayKeyboardAction(a: KeyboardAction) {
    displayKeyboardAction(a)
  }

  get groups(): _.Dictionary<KeyboardAction[]> {
    return _(settings.keyboardShortcuts).groupBy('group').value()
  }
}
