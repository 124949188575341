













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PlayerBarButton extends Vue {
  @Prop({ default: 70 }) size!: number
  @Prop({ default: false }) disabled!: boolean
}
