














































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import bus from '../service/bus'
import * as history from '../store/history.store'
import settings from '../store/settings.store'
import presets from '../presets'
import { RecycleScroller } from 'vue-virtual-scroller'
import HighlightRange from './helper/HighlightRange.vue'
import Checkbox from './helper/Checkbox.vue'
import * as xlsx from 'xlsx'

import {
  TranscriptEvent,
  TokenTierType,
  TranscriptTier,
  SearchResult
} from '@/types/transcript'
import { timeFromSeconds } from '@/util'
import store from '@/store'
import EventService from '@/classes/event.class'
import TranscriptAudio from '@/classes/transcript-audio.class'

@Component({
  components: {
    RecycleScroller,
    HighlightRange,
    Checkbox
  }
})
export default class Search extends Vue {

  settings = settings
  toTime = timeFromSeconds
  transcript = store.transcript!

  resultItemHeight = 40
  caseSensitive = false
  useRegEx = false
  showIpaKeyboard = false

  searchResultEventCounter = 0

  isEventSelected(id: number) {
    return this.transcript.isEventSelected(id)
  }

  debouncedHandleSearch = _.debounce(this.handleSearch, 200)

  mounted() {
    bus.$on('focusSearch', () => {
      if (this.$refs.input instanceof HTMLInputElement) {
        this.$refs.input.focus()
        this.$refs.input.select()
      }
    })
  }

  playEvent(e: TranscriptEvent) {
    if (this.transcript.audio !== null) {
      this.transcript.audio.playEvent(e)
    }
  }

  onFocus() {
    history.stopListening()
  }

  onBlur() {
    history.startListening()
  }

  getSelectedSpeakersLength(): number {
    return _.filter(this.transcript.meta.speakers, s => s.searchInSpeaker === true).length
  }

  getSelectedTiersLength(): number {
    return this.transcript.meta.tiers.filter(s => s.searchInTier === true).length
  }

  areAllTiersSelected(): boolean {
    return this.transcript.meta.tiers.every(t => t.searchInTier === true)
  }

  areAllSpeakersSelected(): boolean {
    return _.every(this.transcript.meta.speakers, s => s.searchInSpeaker === true)
  }

  get searchSettings() {
    return {
      caseSensitive: this.caseSensitive,
      useRegEx: this.useRegEx,
      searchInSpeakers: this.transcript.meta.speakers,
      searchInTiers: this.transcript.meta.tiers
    }
  }

  @Watch('searchSettings', { deep: true })
  onUpdateSearchSettings() {
    this.handleSearch(this.transcript.uiState.searchTerm || '')
  }

  showEventIfExists(e: TranscriptEvent) {
    const i = this.transcript.findEventIndexById(e.eventId)
    if (i > -1) {
      this.transcript.selectEvent(e)
      this.transcript.scrollToAudioEvent(e)
      this.transcript.scrollToTranscriptEvent(e)
    }
  }

  async exportResultsExcel(ress: SearchResult[]) {
    const rows = ress.map(res => {
      let matched = ''
      let tokenType = ''
      if (EventService.isTokenTier(res.tierId)) {
        const tokenIndex = EventService.getTokenIndexByCharacterOffset(
          res.event.speakerEvents[res.speakerId].tokens,
          res.offset,
          res.tierId
        )
        // tslint:disable-next-line:max-line-length
        const token = res.event.speakerEvents[res.speakerId].tokens[tokenIndex].tiers[res.tierId as TokenTierType] || { type: null, text: '' }
        const t = presets[settings.projectPreset].tokenTypes.find(tt => tt.id === token.type)
        if (t !== undefined) {
          tokenType = t.name
        }
        matched = token.text
      } else {
        matched = EventService.getTextFromTier(res.event, res.tierId, res.speakerId)
      }
      const prev = this.transcript.getPreviousEvent(res.event.eventId)
      const next = this.transcript.getNextEvent(res.event.eventId)
      return {
        transcript_name: this.transcript.meta.transcriptName,
        transcript_setting: '',
        speaker_name: this.transcript.meta.speakers[Number(res.speakerId)].k,
        tier_name: res.tierId,
        matched_token: matched,
        token_type: tokenType,
        left_context: prev !== undefined ? EventService.getTextFromTier(prev, res.tierId, res.speakerId) : '',
        content: res.text,
        right_context: next !== undefined ? EventService.getTextFromTier(next, res.tierId, res.speakerId) : '',
        event_audio: this.transcript.audio ? TranscriptAudio.createMediaFragmentUrl(this.transcript.audio.url, res.event) : ''
      }
    })
    const sheet = xlsx.utils.json_to_sheet(rows)
    const file = xlsx.writeFile(
      { Sheets: { sheet }, SheetNames: [ 'sheet' ], },
      this.transcript.meta.transcriptName
      + '_search_'
      + this.transcript.uiState.searchTerm.replace(/[^a-z0-9]/gi, '_')
      + '.xlsx'
    )
  }

  get selectedResultIndex(): number|null {
    if (this.transcript.uiState.selectedEventIds.length !== 1) {
      return null
    } else {
      const eId = this.transcript.uiState.selectedEventIds[0]
      const i = _(this.transcript.uiState.searchResults).findIndex((e) => e.event.eventId === eId)
      if (i > -1) {
        return i + 1
      } else {
        return null
      }
    }
  }

  searchEvents(
    term: string,
    es: TranscriptEvent[],
    speakerIds: string[],
    tiers: TranscriptTier[]
  ): SearchResult[] {
    console.log({ speakerIds, tiers })
    this.searchResultEventCounter = 0
    const termLength = term.length
    let resultId = 0
    let regex: RegExp|null = null
    try {
      regex = new RegExp(term)
    } catch (e) {
      // it failed.
    }
    const r = es.reduce((res, e, i) => {
      let index = -1
      let offsetEnd = -1
      // tslint:disable-next-line:forin
      for (const speakerId of speakerIds) {
        if (e.speakerEvents[speakerId] !== undefined) {
          // TODO: findMatch()
          for (const tier of tiers) {
            // search event tiers
            if (e.speakerEvents[speakerId].speakerEventTiers[tier.id] !== undefined) {
              const s = (e.speakerEvents[speakerId].speakerEventTiers[tier.id] || { text: '' }).text
              // regex
              if (this.useRegEx && this.isValidRegex && regex !== null) {
                const match = regex.exec(s)
                if (match !== null) {
                  index = match.index
                  offsetEnd = index + match[0].length
                }
              // case insensitive
              } else if (this.caseSensitive === false) {
                index = s.toLocaleLowerCase().indexOf(term.toLocaleLowerCase())
                offsetEnd = index + termLength
              // normal
              } else {
                index = s.indexOf(term)
                offsetEnd = index + termLength
              }
              if (index > -1) {
                resultId = resultId + 1
                res.push({
                  resultId,
                  offset: index,
                  offsetEnd,
                  text: s,
                  speakerId,
                  tierId: tier.id,
                  event: e
                })
                index = -1
              }
            // search token tiers
            } else if (tier.type === 'token') {
              const s = e.speakerEvents[speakerId].tokens.map(t => t.tiers[tier.id].text).join(' ')
              // regex
              if (this.useRegEx && this.isValidRegex && regex !== null) {
                const match = regex.exec(s)
                if (match !== null) {
                  index = match.index
                  offsetEnd = index + match[0].length
                }
              // case insensitive
              } else if (this.caseSensitive === false) {
                index = s.toLocaleLowerCase().indexOf(term.toLocaleLowerCase())
                offsetEnd = index + termLength
              // normal
              } else {
                index = s.indexOf(term)
                offsetEnd = index + termLength
              }
              resultId = resultId + 1
              if (index > -1) {
                res.push({
                  resultId,
                  offset: index,
                  offsetEnd,
                  text: s,
                  speakerId,
                  tierId: tier.id,
                  event: e
                })
                index = -1
              }
            }
          }
        }
      }
      if (index !== -1) {
        this.searchResultEventCounter = this.searchResultEventCounter + 1
      }
      return res
    }, [] as SearchResult[])
    return r
  }

  @Watch('transcript.events')
  onUpdateEvents() {
    this.handleSearch(this.transcript.uiState.searchTerm)
  }

  handleSearch(term: string) {
    if (this.transcript.uiState.searchTerm === '') {
      this.transcript.uiState.searchResults = []
    } else {
      const search = this.caseSensitive ? term : term.toLowerCase()
      let regex: RegExp|null = null
      try {
        regex = new RegExp(search)
      } catch (e) {
        // it failed.
      }
      window.requestIdleCallback(() => {
        this.transcript.uiState.searchResults = this.searchEvents(
          term,
          this.transcript.events,
          _(this.transcript.meta.speakers)
            .pickBy(s => s.searchInSpeaker === true)
            .map((s, k) => String(k)).value(),
          this.transcript.meta.tiers.filter(t => t.searchInTier === true)
        )
      })
    }
  }

  get isValidRegex() {
    try {
      const y = new RegExp(this.transcript.uiState.searchTerm)
      return true
    } catch (e) {
      return false
    }
  }

  scrollToSearchResult(e: TranscriptEvent) {
    const i = this.transcript.uiState.searchResults.findIndex(r => r.event.eventId === e.eventId)
    const offset = i * this.resultItemHeight
    requestAnimationFrame(() => {
      const s = this.$el.querySelector('.scroller')
      if (s instanceof HTMLElement) {
        s.scrollTop = offset
      }
    })
  }

  async goToResult(e: TranscriptEvent|undefined) {
    if (e !== undefined) {
      this.transcript.scrollToTranscriptEvent(e)
      this.transcript.scrollToAudioEvent(e)
      this.transcript.selectEvent(e)
      this.scrollToSearchResult(e)
    }
  }

  findNext() {
    const selectedEvent = this.transcript.getSelectedEvent()
    const e = this.transcript.findNextEventAt(
      selectedEvent
        ? selectedEvent.endTime
        : 0,
      this.transcript.uiState.searchResults.map(r => r.event)
    )
    if (e !== undefined) {
      this.goToResult(e)
    } else if (this.transcript.uiState.searchResults.length > 0) {
      this.goToResult(this.transcript.uiState.searchResults[0].event)
    }
  }

  findPrevious() {
    const selectedEvent = this.transcript.getSelectedEvent()
    const e = this.transcript.findPreviousEventAt(
      selectedEvent
        ? selectedEvent.endTime
        : 0,
      this.transcript.uiState.searchResults.map(r => r.event)
    )
    if (e !== undefined) {
      this.goToResult(e)
    } else if (this.transcript.uiState.searchResults.length > 0) {
      const last = _(this.transcript.uiState.searchResults).last()
      if (last) {
        this.goToResult(last.event)
      }
    }
  }
}
