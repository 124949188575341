



































import { Vue, Component, Prop } from 'vue-property-decorator'
import ResizeEvent from './helper/ResizeEvent.vue'
import { mutation } from '../store/history.store'
import settings from '../store/settings.store'
// import { morph } from 'quasar'

import { TranscriptEvent } from '@/types/transcript'
import store from '@/store'

@Component({
  components: {
    ResizeEvent
  }
})
export default class SegmentBox extends Vue {

  @Prop({ required: true }) event!: TranscriptEvent
  @Prop() previousEvent?: TranscriptEvent
  @Prop() nextEvent?: TranscriptEvent

  transcript = store.transcript!
  settings = settings

  scrollToTranscriptEvent(e: TranscriptEvent) {
    return this.transcript.scrollToTranscriptEvent(e)
  }

  selectEvent(e: TranscriptEvent) {
    return this.transcript.selectEvent(e)
  }

  selectEventRange(e: TranscriptEvent) {
    return this.transcript.selectEventRange(e)
  }

  selectOrDeselectEvent(e: TranscriptEvent) {
    return this.transcript.selectOrDeselectEvent(e)
  }

  isEventSelected(id: number) {
    return this.transcript.isEventSelected(id)
  }

  get hasOverlap() {
    const x = (
      this.previousEvent !== undefined &&
      Number(this.previousEvent.endTime.toPrecision(2)) > Number(this.event.startTime.toPrecision(2))
    )
    if (x) {
      console.log('overlap', this.event, this.previousEvent, this.nextEvent)
    }
    return x
  }

  playEvent(e: TranscriptEvent) {
    if (this.transcript.audio !== null) {
      this.transcript.audio.playEvent(e)
    }
  }

  get offset() {
    return Number(this.event.startTime) * settings.pixelsPerSecond
  }

  get width(): number {
    return (Number(this.event.endTime) - Number(this.event.startTime)) * settings.pixelsPerSecond
  }

  onResizeEnd(e: any) {
    const startTime = e.current.left / settings.pixelsPerSecond
    const endTime = e.current.right / settings.pixelsPerSecond
    if (e.next !== null && this.nextEvent !== undefined) {
      const nextStartTime = e.next.left / settings.pixelsPerSecond
      mutation(this.transcript.resizeEvents({ ...this.event, startTime, endTime }, { ...this.nextEvent, startTime: nextStartTime }))
    } else if (e.previous !== null && this.previousEvent !== undefined) {
      const previousEndTime = e.previous.right / settings.pixelsPerSecond
      mutation(this.transcript.resizeEvents({ ...this.previousEvent, endTime: previousEndTime }, { ...this.event, startTime, endTime }))
    } else {
      mutation(this.transcript.resizeEvents({ ...this.event, startTime, endTime }))
    }
  }
}
