var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready && _vm.aKeys.length > 0)?_c('div',{staticClass:"ipa-overlay",class:[
    'ipa-overlay',
    _vm.$vuetify.dark && 'dark-theme'
  ],style:({
    top: _vm.top + 'px',
    left: _vm.left + 'px',
    transform: _vm.directionV === 'top' ? 'translateY(-100%)' : 'none',
    maxWidth: _vm.maxWidth + 'px'
  })},_vm._l((_vm.aKeys),function(aKey){return _c('div',{key:aKey.k,staticStyle:{"white-space":"nowrap"}},_vm._l((aKey.a),function(pKey){return _c('button',{key:pKey,ref:"aBtns",refInFor:true,staticClass:"ipa-btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function (e) { return _vm.setKey(e, aKey.k, pKey); })($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.unsetKeys()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return (function (e) { return _vm.setKey(e, aKey.k, pKey); })($event)},"blur":_vm.blur}},[_vm._v(" "+_vm._s(pKey)+" ")])}),0)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }