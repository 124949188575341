var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"waveform-outer",class:{
    disabled: _vm.disabled,
    loading: _vm.loading
  },style:(_vm.containerStyle),on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }return _vm.emitScroll($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }return _vm.emitScroll($event)}],"mousewheel":_vm.debouncedZoom,"mousedown":function($event){return _vm.$emit('mousedown', $event)}}},[_c('div',{ref:"svgContainer",staticClass:"wave-form",style:({
      height: (_vm.height + "px"),
      width: (_vm.totalWidth + "px")
    }),on:{"mousewheel":_vm.scrollOrZoomPreview,"scroll":_vm.onScroll}},[_c('div',{class:[
      'fade-in-out-quick',
      'second-marker-row',
      _vm.hideSecondMarkers === true && 'hidden'
    ]}),_c('div',{staticClass:"wave-form-inner",style:(_vm.stageStyle)},[_vm._l((Array(_vm.amountDrawSegments)),function(x,i){return _c('div',{key:i,class:[
          'wave-form-segment',
          'draw-segment-' + i
        ],style:({
          transform: ("scaleY(" + _vm.scaleFactorY + ")"),
          left: _vm.drawWidth * i + 'px',
          width: i + 1 < _vm.amountDrawSegments ? _vm.drawWidth + 'px' : 'auto',
          height: _vm.height + 'px'
        }),on:{"dblclick":function($event){$event.stopPropagation();return _vm.addEventAt($event)}}},[_c('div',{staticClass:"wave-form-placeholder",style:({marginTop: _vm.height / 2 + 'px'})})])}),_vm._t("default"),(_vm.settings.showSegmentBoxes)?_c('div',{class:[
          'fade-in-out-quick',
          'segment-box-container',
          _vm.hideSegments === true && 'hidden'
        ]},_vm._l((_vm.visibleEvents),function(event,i){return _c('segment-box',{key:event.eventId,attrs:{"event":event,"previous-event":_vm.visibleEvents[i - 1],"next-event":_vm.visibleEvents[i + 1]},nativeOn:{"contextmenu":function($event){$event.stopPropagation();$event.preventDefault();return (function (e) { return _vm.$emit('show-menu', e); })($event)}}})}),1):_vm._e()],2)]),_c('v-layout',{staticStyle:{"margin-top":"-40px","padding-bottom":"10px"},attrs:{"row":""}},[_c('v-flex',{staticClass:"ml-3",staticStyle:{"position":"relative"},attrs:{"xs12":""}},[_c('scrollbar',{staticClass:"scrollbar",attrs:{"update-on":"updateWaveformScrollbar","max-time":_vm.audio.duration},on:{"scroll":_vm.scrollFromScrollbar}}),_c('div',{staticClass:"overview",style:({
          height: _vm.overviewHeight + 'px'
        })},[_c('div',{ref:"overview",staticClass:"overview-waveform"},[_c('svg',{staticStyle:{"width":"100%"},attrs:{"preserveAspectRatio":"none","viewBox":("0 0 " + _vm.overviewSvgWidth + " 60"),"width":_vm.overviewSvgWidth,"height":"60"}},_vm._l(((_vm.overviewSvgWidth / 10)),function(i){return _c('line',{key:i,attrs:{"stroke-width":"1","stroke":_vm.settings.darkMode ? '#353535' : '#EEEEEE',"stroke-linecap":"round","x1":i * 10,"x2":i * 10,"y1":"30","y2":"40"}})}),0)])]),_vm._t("overview")],2),_c('v-flex',[_c('scroll-lock-button',{staticStyle:{"margin-top":"10px"},attrs:{"value":_vm.settings.lockScroll},on:{"input":_vm.handleScrollLockToggle}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }