





















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SegmentTranscript from './SegmentTranscript.vue'
import { groupConsecutiveBy, timeFromSeconds } from '../util'
import { RecycleScroller } from 'vue-virtual-scroller'
import _ from 'lodash'

import { TranscriptEvent } from '@/types/transcript'

import {
  history,
  jumpToState,
  goToInitialState,
  HistoryEventAction
} from '../store/history.store'
import store from '@/store'
import EventService from '@/classes/event.class'
import Transcript from '@/classes/transcript.class'

@Component({
  components: {
    SegmentTranscript,
    RecycleScroller
  }
})
export default class EditHistory extends Vue {

  @Prop({ required: true }) transcript!: Transcript

  history = history
  toTime = timeFromSeconds
  goToInitialState = goToInitialState
  hoveredEvent: HistoryEventAction|null = null
  menuX = 0
  menuY = 0

  playEvent(e: TranscriptEvent) {
    if (this.transcript.audio !== null) {
      this.transcript.audio.playEvent(e)
    }
  }

  get defaultTier() {
    return this.transcript.meta.defaultTier || 'text'
  }

  get groupedHistoryActions(): HistoryEventAction[] {
    // group consecutive historyEventActions by type and all "before" eventIds
    const groups = groupConsecutiveBy(history.actions, (ha: HistoryEventAction) => {
      return ha.type + '__' + EventService.sortEvents(ha.before).map(e => e.eventId).join('__')
    }) as HistoryEventAction[][]
    // use the first historyEventAction for the "before" state, and the last for the "after" state.
    return _(groups)
      .map(group => ({...group[0], after: group.length > 0 ? group[group.length - 1].after : []}))
      .flatten()
      .value()
  }

  showEventIfExists(e: TranscriptEvent) {
    if (this.transcript !== null) {
      const i = this.transcript.findEventIndexById(e.eventId)
      if (i > -1) {
        this.transcript.selectEvent(e)
        this.transcript.scrollToAudioEvent(e)
        this.transcript.scrollToTranscriptEvent(e)
      }
    }
  }

  undoOrRedoUntil(action: HistoryEventAction) {
    if (action.after[0] && this.transcript !== null) {
      this.transcript.selectEvent(action.after[0])
      this.transcript.scrollToAudioEvent(action.after[0])
      this.transcript.scrollToTranscriptEvent(action.after[0])
    }
    jumpToState(action, true)
  }

  handleEventMouseOver(ev: MouseEvent, e: HistoryEventAction) {
    const rect = (ev.currentTarget as HTMLElement).getBoundingClientRect()
    this.menuX = rect.left
    this.menuY = rect.top
    // no more than three events in preview.
    // (in case somebody edit’s thousands of events at once)
    this.hoveredEvent = {
      ...e,
      before: e.before.slice(0, 3),
      after: e.after.slice(0, 3)
    }
  }

  handleEventMouseOut() {
    this.hoveredEvent = null
  }

}
