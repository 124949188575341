







































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { saveAs } from 'file-saver'
import * as Sentry from '@sentry/browser'
import WaveFormLoadingPlaceholder from './WaveFormLoadingPlaceholder.vue'
import _ from 'lodash'

import PlayerBar from './PlayerBar.vue'
import WaveForm from './Waveform.vue'
import SettingsView from './Settings.vue'
import EventInspector from './EventInspector.vue'
import SearchResultsInline from './SearchResultsInline.vue'
import WarningsInline from './WarningsInline.vue'
import TranscriptEditor from './TranscriptEditor.vue'
import PlayHead from './PlayHead.vue'
import DropAudioFile from './DropAudioFile.vue'
import transcriptSettings from './TranscriptSettings.vue'
import KeyboardShortcut from './helper/KeyboardShortcut.vue'
import TimeSelection from './TimeSelection.vue'
import ForceRefresh from './helper/ForceRefresh.vue'

import { TranscriptEvent } from '@/types/transcript'
import { saveChangesToServer, resetServerTranscript } from '../service/backend-server.service'
import { handleGlobalShortcut, keyboardShortcuts, displayKeyboardAction } from '../service/keyboard.service'
import kaldiService from '../service/kaldi/kaldiService'
import { isCmdOrCtrl } from '../util'
import {
  history,
  mutation,
  startListening as startUndoListener,
  stopListening as stopUndoListener
} from '../store/history.store'
import {
  isWaveformEventVisible,
  getScrollLeftAudio
} from '../service/dom.service'
import settings from '../store/settings.store'
import fileService from '../service/disk.service'
import bus from '../service/bus'
import store from '@/store'
import Transcript from '@/classes/transcript.class'
import TranscriptAudio from '@/classes/transcript-audio.class'
import EventService from '@/classes/event.class'
import { getWarnings } from '@/service/warnings.service'
import { distanceInWordsToNow as distance } from 'date-fns'

@Component({
  components: {
    WaveForm,
    WaveFormLoadingPlaceholder,
    TranscriptEditor,
    SettingsView,
    EventInspector,
    transcriptSettings,
    PlayHead,
    SearchResultsInline,
    DropAudioFile,
    PlayerBar,
    KeyboardShortcut,
    WarningsInline,
    TimeSelection,
    ForceRefresh
  }
})

export default class Editor extends Vue {

  @Prop({ required: true }) transcript!: Transcript

  errors: TranscriptEvent[] = []
  store = store
  history = history
  keyboardShortcuts = keyboardShortcuts
  displayKeyboardAction = displayKeyboardAction
  settings = settings

  snackbar: {
    show: boolean
    text: string
    progressType: 'determinate'|'indeterminate'|null,
    progress: 0,
    timeout: number|null
  } = {
    show: false,
    text: '',
    progressType: 'determinate',
    progress: 0,
    timeout: null
  }

  scrollTranscriptIndex: number = 0
  scrollTranscriptTime: number = 0

  showSearch = false
  isMenuVisible = false
  isSaving = false
  menuX = 0
  menuY = 0
  layerX = 0 // this is used for splitting

  distance(d: Date|null): string|null {
    if (d !== null) {
      return distance(d, { addSuffix: true })
    } else {
      return null
    }
  }

  btnBack() {
    this.store.transcript = null
    this.store.status = 'empty'
    this.store.warnings = []
    this.history.actions = []
    this.history.undoRedo = false
    resetServerTranscript()
  }

  getWfScrollLeft(e: MouseEvent) {
    if (!(e.target as HTMLElement).classList.contains('wave-form-inner')) {
      const wf = document.querySelector('.wave-form')
      if (wf instanceof HTMLElement) {
        return wf.scrollLeft
      }
    }
    return 0
  }

  startSelection(e: MouseEvent) {
    this.transcript.deselectEvents()
    this.transcript.uiState.timeSpanSelection.start = (this.getWfScrollLeft(e) + e.clientX) / settings.pixelsPerSecond
    this.transcript.uiState.timeSpanSelection.end = this.transcript.uiState.timeSpanSelection.start
    document.addEventListener('mousemove', this.dragSelection)
    document.addEventListener('mouseup', this.endSelection)
  }

  dragSelection(e: MouseEvent) {
    this.transcript.uiState.timeSpanSelection.end = (this.getWfScrollLeft(e) + e.clientX) / settings.pixelsPerSecond
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
    }
  }

  endSelection(e: MouseEvent) {
    this.transcript.uiState.timeSpanSelection.end = (this.getWfScrollLeft(e) + e.clientX) / settings.pixelsPerSecond
    if (Math.abs((this.transcript.uiState.timeSpanSelection.end || 0) - (this.transcript.uiState.timeSpanSelection.start || 0)) < 0.01) {
      this.transcript.uiState.timeSpanSelection.start = 0
      this.transcript.uiState.timeSpanSelection.end = 0
    }
    document.removeEventListener('mousemove', this.dragSelection)
    document.removeEventListener('mouseup', this.endSelection)
  }

  playEvent(e: TranscriptEvent) {
    if (this.transcript.audio !== null) {
      this.transcript.audio.playEvent(e)
    }
  }

  getSelectedEvent() {
    this.transcript.getSelectedEvent()
  }

  isEventSelected(id: number) {
    this.transcript.isEventSelected(id)
  }

  get duration() {
    return this.transcript.audio?.duration || 0
  }

  async loadAudioFromFile(f: File|Uint8Array) {
    this.transcript.audio = new TranscriptAudio(f)
  }

  reload() {
    window.location.reload()
  }

  async exportAudio() {
    const es = this.transcript.uiState.selectedEventIds.map((id) => this.transcript.getEventById(id)).filter((e): e is TranscriptEvent => e !== undefined)
    if (this.transcript.audio !== null) {
      await this.transcript.audio.exportEventAudio(es, this.transcript.meta.transcriptName || 'untitled_transcript')
    }
  }

  joinEvents(es: number[]) {
    return mutation(this.transcript.joinEvents(es))
  }

  deleteSelectedEvents() {
    return mutation(this.transcript.deleteSelectedEvents())
  }

  async exportProject() {
    const overviewWave = (document.querySelector('.overview-waveform svg') as HTMLElement).innerHTML
    const f = await fileService.generateProjectFile(this.transcript, overviewWave, this.transcript.audio?.buffer || null, history.actions)
    if (this.transcript.meta.transcriptName === null || this.transcript.meta.transcriptName === '') {
      const name = (prompt('Please enter a name for the transcript', 'untitled_transcript') || 'untitled_transcript') + '.transcript'
      saveAs(f, name)
    } else {
      saveAs(f, this.transcript.meta.transcriptName + '.transcript')
    }
    this.isSaving = false
  }

  async transcribeEvent(e?: TranscriptEvent) {
    if (e !== undefined && this.transcript.audio !== null) {
      const buffer = await TranscriptAudio.decodeBufferTimeSlice(e.startTime, e.endTime, this.transcript.audio.buffer.buffer)
      const result = await kaldiService.transcribeAudio(
        window.location.origin + '/kaldi-models/german.zip',
        buffer,
        (status) => {
          if (status === 'DOWNLOADING_MODEL') {
            this.snackbar = {
              text: 'Downloading German Language Model…',
              show: true,
              progressType: 'indeterminate',
              progress: 0,
              timeout: null
            }
          } else if (status === 'INITIALIZING_MODEL') {
            this.snackbar = {
              text: 'Initializing Model…',
              show: true,
              progressType: 'indeterminate',
              progress: 0,
              timeout: null
            }
          } else if (status === 'PROCESSING_AUDIO') {
            this.snackbar = {
              text: 'Transcribing Audio…',
              show: true,
              progressType: null,
              progress: 0,
              timeout: 2000
            }
          } else if (status === 'DONE') {
            this.snackbar.show = false
          }
        }
      )
      const cleanResult = result.replaceAll(/\d\.\d\d\s/g, '')
      bus.$emit('updateSpeakerEventText', {
        eventId: e.eventId,
        speakerId: Object.keys(this.transcript.meta.speakers)[0],
        text: cleanResult
      })
    }
  }

  async saveTranscript() {
    // if (this.history.actions.length > 0) {
    this.isSaving = true
    if (settings.backEndUrl !== null) {
      try {
        this.transcript.events = EventService.removeBrokenFragmentLinks(this.transcript.events)
        console.log(this.transcript)
        this.transcript.events = await saveChangesToServer(this.transcript)
      } catch (e) {
        Sentry.captureException(e)
        alert('Could not save transcript to server.')
        console.log(e)
      } finally {
        this.isSaving = false
      }
    } else {
      await fileService.saveFile(this.transcript, history.actions)
    }
    this.isSaving = false
  }

  async showMenu(e: MouseEvent) {
    // this is used for splitting
    this.layerX = e.offsetX
    const ev = this.transcript.findEventAt(((await getScrollLeftAudio()) + e.x) / settings.pixelsPerSecond)
    if (ev !== undefined) {
      if (isCmdOrCtrl(e)) {
        this.transcript.addEventsToSelection([ev])
      } else {
        if (!this.transcript.isEventSelected(ev.eventId)) {
          this.transcript.selectEvents([ ev ])
        }
      }
    }
    this.menuX = e.x
    this.menuY = e.y
    this.isMenuVisible = true
  }

  splitEventFromMenu(event?: TranscriptEvent) {
    if (event) {
      const splitAt = this.layerX / settings.pixelsPerSecond
      this.splitEvent(event, splitAt)
    }
  }

  showEventInspector(e?: TranscriptEvent) {
    if (e !== undefined) {
      this.transcript.uiState.inspectedEventId = e.eventId
    }
  }

  async splitEvent(e: TranscriptEvent, at: number) {
    const [ leftEvent ] = mutation(this.transcript.splitEvent(e, at))
    if (!(await isWaveformEventVisible(leftEvent))) {
      this.transcript.scrollToAudioEvent(leftEvent)
    }
  }

  mounted() {
    startUndoListener()
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (history.actions.length > 0) {
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    }
    bus.$on('scrollWaveform', this.hideMenu)
    document.addEventListener('keydown', handleGlobalShortcut)
    if (store.status === 'new') {
      this.transcript.uiState.showTranscriptMetaSettings = true
    }
  }

  beforeDestroy() {
    stopUndoListener()
    document.removeEventListener('keydown', handleGlobalShortcut)
  }

  hideMenu() {
    if (this.isMenuVisible === true) {
      this.isMenuVisible = false
    }
  }

  @Watch('settings.showWarnings', { deep: true })
  onWarningsSettingsUpdate() {
    this.getWarnings()
  }

  @Watch('settings.maxEventGap')
  onGapSettingsUpdate() {
    this.getWarnings()
  }

  @Watch('transcript.events')
  onEventsUpdate() {
    this.debouncedGetWarnings()
  }

  @Watch('settings.projectPreset')
  onPresetUpdate() {
    this.getWarnings()
  }

  async getWarnings() {
    await this.$nextTick()
    window.requestIdleCallback(() => {
      store.warnings = getWarnings(this.transcript.events)
    })
  }

  debouncedGetWarnings = _.debounce(this.getWarnings, 500)
}

